<template>
  <div style="width: 300px; margin: 0px auto;" v-loading="btnLogin">
    <div class="alert-title">Please enter your username or email address. You will receive a link to create a new password via email.</div>
    <el-form label-position="top" ref="loginForm" :rules="rules" label-width="80px" :model="loginForm">
      <el-form-item label="Email Address *" prop="email">
        <el-input v-model="loginForm.email"></el-input>
      </el-form-item>
      <el-form-item label="New Password *" prop="passwd">
        <el-input v-model="loginForm.passwd" show-password></el-input>
      </el-form-item>
    </el-form>
    <div class="login-btn-box">
      <div class="login-submit" @click="looseSubmit">Change Password</div>
      <div class="back-btn" @click="goBack">Back</div>
    </div>
  </div>
</template>
<script>
import { forgot } from "@/api/index" 
export default {
  data () { 
    return {
      loginForm: {
        name:'',
        passwd:'',
      },
      remberMe:false,
      rules: {
        email: [
          { required: true, message: 'please enter Email Address', trigger: 'change' }
        ],
        passwd: [
          { required: true, message: 'please enter password', trigger: 'change' }
        ],
      },
      btnLogin:false,
    }
  },
  methods: {
    goBack () { 
      this.$emit('goBack')
    },
    looseSubmit () { 
      this.btnLogin = true
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let obj = {
            email:this.loginForm.email,
            passwd:this.loginForm.passwd,
          }
          forgot(obj).then(() => {
            this.btnLogin = false
            this.$message({
              message: 'Mail sent successfully! Please check your email and verify it!',
              type: 'success'
            });
            this.$emit('looseSubmit')
          }).catch(()=>{
            this.btnLogin = false
          })
        } else {
          return false;
        }
      });
    },
    goLogin () { 
      this.$emit('goLogin')
    },
  }
}
</script>
<style scoped>
.alert-title{
  color: #ff0000;
}
.login-btn-box{
  display: flex;
  margin-bottom: 18px;
}
.login-submit{
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #fff;
  background-color: #eb3349;
  cursor: pointer;
 
}
.back-btn{
  margin-left: 10px;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #eb3349;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eb3349;;
}
.forget{
  padding-right: 20px;
  cursor: pointer;
}
.forget:hover{
  color: #eb3349;;
}
</style>
<template>
  <div id="headerView" ref="headerView">
    <div class="head-row-box">
      <div class="head-row" ref="headRow">
        <img class="logo" src="../../static/images/home/logo_new.png" @click="jumpUrl('/')" alt="">
        <div class="nav-row">
          <div class="net-name">Fiction Cloud</div>
          <div class="nav-item" @click="jumpUrl('/')">Home</div>
          <div class="nav-item" @click="jumpUrl('/coins')">Coins</div>
          <!-- <div @mouseenter="types = true" @mouseleave="leaveType">Type</div> -->
          <div class="nav-item" v-if="isHasLogin" @click="jumpUrl('/bookMark')">BookMark</div>
        </div>
        <div class="search-row">
          <!-- <el-input placeholder="Search..." v-model="searchData" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input> -->
          <div class="search-a" @click="isShowSearch = true">
            <i class="el-icon-search"></i>
            <span>Search...</span>
          </div>
          <div v-if="!isHasLogin" class="login-btn" style="width: 64px;" @click="dialogLogin = true">Login</div>
          <div v-if="!isHasLogin" class="login-btn" style="width: 82px;" @click="registerLogin = true">Register</div>
          <div v-if="isHasLogin" class="user-infor">
            <i class="el-icon-user"></i>
            <el-popover
              placement="bottom"
              width="100"
              trigger="hover">
              <div>
                <div class="has-money">
                  <i class="el-icon-coin"></i>
                  <span>{{balance}}</span>
                </div>
                <!-- <div class="logOut" @click="closeAuto">Turn off automatic purchase chapter</div> -->
                <div class="logOut" @click="logOut">Log out</div>
              </div>
              <span slot="reference">{{userName}}</span>
            </el-popover>
            
          </div>
        </div>
      </div>
    </div>
    <div class="transition-box" 
        v-if="isType"
        @mouseenter="types = true;mouseChild=true"
        @mouseleave="types = false;mouseChild=false">
        <div class="type-box" ref="typeBox">
          <div class="type-box-title">type</div>
          <div class="type-box-item" @click="goSeries({id: -1,Title:'ALL'})">All</div>
          <div class="type-box-item" v-for="(item,index) in typeList" :key="index" @click="goSeries(item)">{{item.Title}}</div>
          <!-- <div class="type-box-item" @click="goSeries('Immortal Fantasy')">Immortal Fantasy</div>
          <div class="type-box-item" @click="goSeries('Science Fiction Future')">Science Fiction Future</div>
          <div class="type-box-item" @click="goSeries('Apocalyptic Disaster')">Apocalyptic Disaster</div>
          <div class="type-box-item" @click="goSeries('Magic Fantasy')">Magic Fantasy</div>
          <div class="type-box-item" @click="goSeries('Urban Exotic')">Urban Exotic</div>
          <div class="type-box-item" @click="goSeries('Horror Thriller')">Horror Thriller</div>
          <div class="type-box-item" @click="goSeries('Zombie Trend')">Zombie Trend</div>
          <div class="type-box-item" @click="goSeries('Online Game World')">Online Game World</div>
          <div class="type-box-item" @click="goSeries('Vampire Theme')">Vampire Theme</div>
          <div class="type-box-item" @click="goSeries('War Favors and Spiritual Beasts')">War Favors and Spiritual Beasts</div>
          <div class="type-box-item" @click="goSeries('Romantic Emotion')">Romantic Emotion</div> -->
        </div>
      </div>
    <!-- type类型 -->
    <!-- <transition name="el-zoom-in-top">
      <div v-show="types" class="transition-box" 
        @mouseenter="types = true;mouseChild=true"
        @mouseleave="types = false;mouseChild=false">
        <div class="type-box">
          <div>Immortal Fantasy</div>
          <div>Science Fiction Future</div>
          <div>Apocalyptic Disaster</div>
          <div>Magic Fantasy</div>
          <div>Urban Exotic</div>
          <div>Horror Thriller</div>
          <div>Zombie Trend</div>
          <div>Online Game World</div>
          <div>Vampire Theme</div>
          <div>War Favors and Spiritual Beasts</div>
          <div>Romantic Emotion</div>
        </div>
      </div>
    </transition> -->
    <!-- 登陆弹窗 -->
    <el-dialog
      title="SIGN IN"
      :visible.sync="dialogLogin"
      width="360px"
      :modal-append-to-body="false"
      :close-on-click-modal="false">
      <loginView v-if="dialogLogin" @loosePass="loosePass" @loginSubmit="loginSubmit" @goRegister="registerLogin = true;dialogLogin = false" @goBack="dialogLogin = false"></loginView>
    </el-dialog>
    <!-- 注册弹窗 -->
    <el-dialog
      title="REGISTER"
      :visible.sync="registerLogin"
      width="360px"
      :modal-append-to-body="false"
      :close-on-click-modal="false">
      <registerView v-if="registerLogin" @loosePass="loosePass" @registerSubmit="registerSubmit" @goLogin="goLogin" @goBack="registerLogin = false"></registerView>
    </el-dialog>
    <!-- 丢失密码弹窗 -->
    <el-dialog
      title="Retrieve password"
      :visible.sync="looseDialog"
      width="360px"
      :modal-append-to-body="false"
      :close-on-click-modal="false">
      <loosePassword v-if="looseDialog" @registerSubmit="registerSubmit" @looseSubmit="looseSubmit" @goBack="looseDialog = false"></loosePassword>
    </el-dialog>
    <!-- 搜索弹窗 -->
    <searchDialog v-if="isShowSearch" @closeSearch="isShowSearch = false"></searchDialog>
    <!-- 打开搜索和登陆 -->
    <div class="show-login-btn" @click="loginDrawer = true">
      <i class="el-icon-s-unfold"></i>
    </div>
    <el-drawer
      title="我是标题"
      size='60%'
      :direction="'ltr'"
      :visible.sync="loginDrawer"
      :with-header="false">
      <div>
        <div class="search-a" @click="isShowSearch = true;loginDrawer= false">
          <i class="el-icon-search"></i>
          <span>Search...</span>
        </div>
        <div style="display:flex"> 
          <div v-if="!isHasLogin" class="login-btn" style="width: 64px;" @click="dialogLogin = true">Login</div>
          <div v-if="!isHasLogin" class="login-btn" style="width: 82px;" @click="registerLogin = true">Register</div>
        </div>
        <div v-if="isHasLogin" class="user-infor">
          <i class="el-icon-user"></i><span slot="reference">{{userName}}</span>
          <div class="has-money">
            <i class="el-icon-coin"></i>
            <span>{{balance}}</span>
          </div>
          <!-- <div class="logOut" @click="closeAuto">Turn off automatic purchase chapter</div> -->
          <div class="logOut" @click="logOut">Log out</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import loginView from './loginView.vue'
import registerView from './registerView.vue'
import loosePassword from './loosePassword.vue'
import searchDialog from './searchDialog.vue'
import store from '../store/index'
import { getCategory, getBalance, changeAutoPay, getFromCategory } from "@/api/index" 
export default {
  components: { loginView, registerView, loosePassword, searchDialog },
  props: {
    isType: {
      type: Boolean,
      default:true,
    }
  },
  data(){
    return {
      searchData: '',
      dialogLogin: false, //登陆弹窗
      registerLogin: false, //注册弹窗
      looseDialog: false, //密码找回
      types: false,//类型
      mouseChild: false,
      isShowSearch:false,
      isHasLogin:false, //是否已经登录
      userName:'',
      loginDrawer:false,
      typeList:[],
      balance:0
    }
  },
  computed:{ 
    isLogin () {
      console.log(store.state.isLogin)
      return store.state.isLogin;
    }
  },
  watch: { 
    isLogin (newVal, oldValue) { 
      console.log(newVal, oldValue)
      this.dialogLogin = !newVal
    },
  },
  mounted(){
    this.isHasLogin = localStorage.getItem('token') ? true : false
    this.userName = localStorage.getItem('username')
    this.getCategory()
    let token = localStorage.getItem('token')
    if(token){
      this.getBalance()
    }
    if(window.screen.availWidth < 1020){
      let screenWidth = window.screen.availWidth
      let scaling = screenWidth / 1020
      this.$refs.headerView.style.width = `500%`
      this.$refs.headRow.style.margin = `0px`
      this.$refs.typeBox.style.margin = `0px`
      
    }
  },
  methods: {
    // 关闭自动购买章节
    closeAuto(){
      let obj = {
        autoPay : 0,
      }
      changeAutoPay(obj).then(() => {
        this.$message({
          message: 'Closed successful',
          type: 'success'
        });
        this.getBalance()
      })
    },
    // 获取余额
    getBalance(){
      getBalance().then(res => {
        localStorage.setItem('autoPay', res.data.Main.autoPay)
        this.balance = res.data.Main.money
      })
    },
    // 获取小说所有类型
    getCategory () { 
      getCategory().then(res => {
        console.log(res.data, '所有的类型')
        this.typeList = res.data
      })
    },
    // 登出
    logOut(){
      localStorage.setItem('token', '')
      localStorage.setItem('username', '')
      this.isHasLogin = false
    },
    // 登陆
    loginSubmit () { 
      this.dialogLogin = false
      this.isHasLogin = true
      this.userName = localStorage.getItem('username')
      this.getBalance()
      this.loginDrawer = false
      // location.reload()
    },
    // 注册
    registerSubmit () { 
      this.registerLogin = false
      this.dialogLogin = true
    },
    // 跳转到登陆
    goLogin () { 
      this.registerLogin = false
      this.dialogLogin = true
      this.loginDrawer = false
    },
    // 打开找回密码弹窗
    loosePass () { 
      this.dialogLogin = false
      this.registerLogin = false
      this.looseDialog = true
    },
    // 注册
    looseSubmit () { 
      this.looseDialog = false
    },
    leaveType () { 
      setTimeout(() => { 
        if (!this.mouseChild) { 
          this.types = false
        }
      },300)
    },
    // 顶部跳转
    jumpUrl (url) { 
      this.$router.push({
        path:url
      })
    },
    // 类型跳转
    goSeries(item){
      let obj = {
        id:item.id,
        pageno:1,
        pagesize:10
      }
      getFromCategory(obj).then(res => {
        if(res.data.list && res.data.list.length > 0){
          if(this.$route.path == '/series'){
            this.$router.replace({
              path:'/series',
              query:{type:item.Title, id:item.id}
            })
            location.reload()
          }else{
            this.$router.push({
              path:'/series',
              query:{type:item.Title, id:item.id}
            })
          }
        }else{
          this.$message('There is currently no data available');
        }
      })
      
    }
  }
}
</script>

<style scoped>
#headerView{
  background: #fff;
  border-bottom: 1px solid #dcdce2;
}
::v-deep.el-dialog__header{
  text-align: center;
}
.head-row-box{
  border-bottom: 1px solid #dcdce2;
}
.head-row{
  display: flex;
  width: 980px;
  padding: 0px 20px;
  height: 80px;
  margin: 0px auto;
  align-items: center;
}
.logo{
  height: 40px;
  width: auto;
  margin-right: 15px;
  cursor: pointer;
}
.nav-row{
  display: flex;
  align-items: center;
  flex: 1;
}
.nav-item{
  color: rgba(18,18,23,.6);
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
} 
.nav-item{
  color: rgba(18,18,23,.6);
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  margin-right: 15px;
} 
.net-name{
  color: rgba(18,18,23,.6);
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  margin-right: 20px;
  color: #72A464;
}
.nav-item:hover{
  color: #EB3349;
}
.search-row{
  display: flex;
  align-items: center;
}
.login-btn{
  padding: 11px;
  border-radius: 8px;
  font-size: 14px;
  color: #FFF;
  background: #EB3349;
  line-height: 40rpx;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  box-sizing: border-box;
}
.user-infor{
  padding: 10px;
}
.user-infor>i{
  font-size: 20px;
}
.user-infor>span{
  padding: 10px;
  color: #333;
}
.transition-box{
  width: 100%;
  padding: 30px 0px 10px;
  background: #FFF;
  z-index: 19999;
  margin-top: 1px;
}
.type-box{
  width: 980px;
padding: 0px 20px;;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}
.type-box-title{
  cursor: pointer;
  padding-right: 20px;
  margin-bottom: 20px;
  transition: all 0.35s;
  font-weight: 600;
  color: #333;
}
.type-box-item{
  padding-right: 20px;
  margin-bottom: 20px;
  transition: all 0.35s;
  cursor: pointer;
}
.type-box-item:hover{
  color: #409EFF;
  text-decoration:underline;
}
.search-a{
  width: 200px;
  height: 40px;
  border-radius: 40px;
  box-sizing: border-box;
  color: rgba(18,18,23,.24);
  font-size: 16px;
  border: 1px solid #d7d8e0;
  line-height: 40px;
  padding-left: 16px;
  transition: all 0.35;
  cursor: pointer;
}
.search-a:hover{
  color: #EB3349;
  border: 1px solid #EB3349;
}
.search-a>span{
  padding-left: 8px;
}
.has-money>i{
  margin-right: 4px;
}
.logOut{
  cursor: pointer;
}
.logOut:hover{
  color: #EB3349;
  text-decoration: underline;
}
.show-login-btn{
  display: none;
}
::v-deep .v-modal{
  display: none !important;
}
/*

@media screen and (max-width : 390px) {
  .head-row{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
  }
  .transition-box{
    width: 100%;
    padding: 30px 20px 10px;
    background: #FFF;
    z-index: 19999;
    margin-top: 1px;
    box-sizing: border-box;
  }
  .type-box{
    width: 100%;
  }
  .search-row{
    display: none;
  }
  .show-login-btn{
    display: block;
    width: 39px;
    height: 27px;
    border-radius: 0px 50px 50px 0px;
    position: fixed;
    left: 0px;
    top: 100px;
    background: #f3f3f3;
    text-align: center;
    line-height: 27px;
    color: #999;
  }
  .search-a{
    margin: 10px;
    width: 170px;
  }
  .has-money{
    padding-top: 20px;
  }
  .logOut{
    margin-top: 6px;
    color: #EB3349;
  }
  .logo{
    height: 30px;
    margin-right: 10px;
  }
}
*/
</style>

import request from "@/utils/request";  //导入封装请求的js文件
 
export function products(params) {
  return request({
    url: "/api/v1/products",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
// 登录
export function login(data) {
  return request({
    url: "index.php?s=api/User/login",
    method: "post",
    data,
    // post请求接口后面拼接参数
    // params: {
    //   currentOpenId: 'currentOpenId',
    //   currentCompanyOpenId: 'currentCompanyOpenId'
    // }
  });
}
// 注册
export function register(data) {
  return request({
    url: "index.php?s=api/User/register",
    method: "post",
    data,
  });
}

// 获取小说详情
export function getChapterList(data) {
  return request({
    url: "index.php?s=api/Chapter/getChapterList",
    method: "post",
    data,
  });
}

// 忘记密码接口
export function forgot(data) {
  return request({
    url: "index.php?s=api/User/forgot",
    method: "post",
    data,
  });
}

// 支付接口
export function pay(params) {
  return request({
    url: "index.php?s=api/Paypal/pay",  //接口路径
    method: "post",  //接口方法
    data:params  //接口参数
  });
}

// Recommend列表
export function getRecommendList(data) {
  return request({
    url: "index.php?s=api/Chapter/getRecommend",
    method: "post",
    data,
  });
}

// 周数据列表
export function weekData(data) {
  return request({
    url: "index.php?s=api/Chapter/getTop",
    method: "post",
    data,
  });
}

// 周数据列表
export function getSwiper(data) {
  return request({
    url: "index.php?s=api/Chapter/getSwiper",
    method: "post",
    data,
  });
}

// 小说收藏
export function setMark(data) {
  return request({
    url: "index.php?s=api/Chapter/mark",
    method: "post",
    data,
  });
}

// 获取所有小说类型
export function getCategory(data) {
  return request({
    url: "index.php?s=api/Chapter/getCategory",
    method: "post",
    data,
  });
}

// 搜索小说
export function getSearck(data) {
  return request({
    url: "index.php?s=api/Chapter/getSearch",
    method: "post",
    data,
  });
}

// 小说某类型列表
export function getFromCategory(data) {
  return request({
    url: "index.php?s=api/Chapter/getFromCategory",
    method: "post",
    data,
  });
}

// 小说某标签列表
export function getFromHotSearch(data) {
  return request({
    url: "index.php?s=api/Chapter/getFromHotSearch",
    method: "post",
    data,
  });
}

// 移除收藏项
export function removeMark(data) {
  return request({
    url: "index.php?s=api/Chapter/removeMark",
    method: "post",
    data,
  });
}

// 书架里面的书
export function myBookshelf(data) {
  return request({
    url: "index.php?s=api/Chapter/myBookshelf",
    method: "post",
    data,
  });
}

// 获取余额
export function getBalance(data) {
  return request({
    url: "index.php?s=api/User/getMe",
    method: "post",
    data,
  });
}

// 购买章节
export function buyChapter(data) {
  return request({
    url: "index.php?s=api/Chapter/orderChapter",
    method: "post",
    data,
  });
}

// 获取金额列表
export function getCharge(data) {
  return request({
    url: "index.php?s=api/Chapter/getCharge",
    method: "post",
    data,
  });
}

// 推荐小说列表
export function guessYouLike(data) {
  return request({
    url: "index.php?s=api/Chapter/guessYouLike",
    method: "post",
    data,
  });
}

// 阅读小说
export function readBook(data) {
  return request({
    url: "index.php?s=api/Chapter/read",
    method: "post",
    data,
  });
}

// 开通自动购买
export function changeAutoPay(data) {
  return request({
    url: "index.php?s=api/Chapter/changeAutoPay",
    method: "post",
    data,
  });
}

// 给小说打分
export function daFen(data) {
  return request({
    url: "index.php?s=api/Chapter/daFen",
    method: "post",
    data,
  });
}

// 给小说添加书签
export function putMark(data) {
  return request({
    url: "index.php?s=api/Chapter/putMark",
    method: "post",
    data,
  });
}

// boolMark
export function myMark(data) {
  return request({
    url: "index.php?s=api/Chapter/myMark",
    method: "post",
    data,
  });
}


// 获取热门标签
export function getTags(data) {
  return request({
    url: "index.php?s=api/Chapter/getTags",
    method: "post",
    data,
  });
}

<template>
  <div class="search-dialog">
    <div class="search-box">
      <div class="content-box">
        <i class="el-icon-search" @click="goIntroduce"></i>
        <el-select
          ref="search"
          v-model="searchValue"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="Search"
          :remote-method="remoteMethod"
          @change="handelSelect"
          :loading="loading">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.Title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="hot-box">
        <div class="hot-content-box">
          <div>Hot search</div>
          <div class="hot-arr">
            <div class="hot-item" :class="{'active-item' : selArr.indexOf(item) != -1}" v-for="(item,index) in hotList" :key="index" @click="addSearch(item)">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      
    </div>
    <div class="mark" @click="closeSearch"></div>
  </div>
</template>

<script>
import { getSearck,getTags,getFromHotSearch } from "@/api/index"
export default {
  data(){
    return {
      searchValue: '',
      options: [],
      value: [],
      loading: false,
      selArr:[],
      hotList:[],
    }
  },
  mounted() {
    this.getTags()
  },
  methods: {
    // 选中搜索
    handelSelect(){
      if(this.$route.path == '/introduce'){
        this.$router.replace({
          path:'./introduce',
          query: {id:this.searchValue}
        })
        location.reload()
      }else{
        this.$router.push({
          path:'./introduce',
          query: {id:this.searchValue}
        })
      }
    },
    getTags(){
      getTags().then(res => {
        this.hotList = res.data
        console.log(res, '热门类型')
      })
    },
    closeSearch () { 
      this.$emit('closeSearch')
    },
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true;
        let obj = {
          key:{
            tags:[...this.selArr],
            words:[query],
          }
        }
        getSearck(obj).then(res => {
          this.loading = false;
          this.isAllowSearch = false
          this.options = res.data
        })
       
      } else {
        this.options = [];
      }
    },
    // 跳转到小说介绍页面
    goIntroduce () { 
      if(this.selArr.length > 0) {
        let title = this.selArr.join(',')
        let obj = {
          keylist:title,
          pageno:1,
          pagesize:10
        }
        getFromHotSearch(obj).then(res => {
          if(res.data.list && res.data.list.length > 0){
            if(this.$route.path == '/series'){
              this.$router.replace({
                path:'/series',
                query:{type:title}
              },
              () => {
                location.reload() //刷新页面
              })
            }else{
              this.$router.push({
                path:'/series',
                query:{type:title}
              })
            }
            
          }else{
            this.$message('There is currently no data available');
          }
        })
        console.log(this.selArr, '选中的标签')
        
        
      }
      
    },
    addSearch (item) { 
      if(this.selArr.length == 0 || this.selArr.indexOf(item) == -1){
        this.selArr.push(item)
      }else{
        this.selArr.splice(this.selArr.indexOf(item),1)
      }
    },
  }
}
</script>

<style scoped>
.search-dialog{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
}
.mark{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(18,18,23,.3);
}
.search-box{
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #dcdce2;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}
::v-deep.el-input__inner{
  border: 0px solid #999;
  background: none;
  width: 940px;
}
.content-box{
  width: 980px;
padding: 0px 20px;;
  margin: 0px auto;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #dcdce2;
  box-sizing: border-box;
  padding-left: 14px;
}
.el-icon-search{
  cursor: pointer;
}
.el-icon-search:hover{
  color: #eb3349;
}
.hot-box{
  padding: 20px 0px;
  background: #fff;
  position: fixed;
  top: 81px;
  left: 0px;
  z-index: 2;
  width: 100%;
}
.hot-content-box{
  width: 980px;
padding: 0px 20px;;
  margin: 0px auto;
}
.hot-arr{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.hot-item{
  padding: 4px 6px;
  font-size: 12px;
  color: #666;
  border-radius: 4px;
  margin: 0px 6px 6px 0px;
  background: #f0f0f3;
  cursor: pointer;
}
.active-item{
  background: #eb3349;
  color: #fff;
}
@media screen and (max-width : 390px) {
  .content-box{
    width: 100%;
  }
  .content-box,.hot-content-box{
    width: 100%;
  }
  .hot-box{
    box-sizing: border-box;
    padding: 10px;
  }
  .content-box{
    display: flex;
    align-items: center;
  }
  ::v-deep.el-input__inner{
    border: 0px solid #999;
    background: none;
    width: 340px;
  }
}
</style>
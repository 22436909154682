import axios from 'axios';
import { MessageBox } from 'element-ui';  //导入element-ui组件库
import store from '../store/index';
 
// 创建axios的对象
const instance = axios.create({

    baseURL: "https://qubox.top",

  timeout: 60000
})
 
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        config.headers.token = localStorage.getItem('token') || '' // 请求头添加token值
        // config.headers.info = ''  //请求头添加info值
        const url = new URL(config.url, config.baseURL || window.location.origin);
        url.searchParams.append('frontend', 'ficcloud'); // 添加查询参数 'frontend=ficcloud'
        config.url = url.toString().replace(url.origin, ''); // 更新config.url，移除origin部分
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
)
 
// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
  (response) => {
      const res = response;
      let isLogin = false;
        if (res.data.code === 500) {
            if(res.data.message.indexOf('balance') == -1){
                MessageBox.alert(res.data.message, 'error', {
                    confirmButtonText: 'submit',
                    type: 'error'
                }).then(() => {
                    
                    // store.dispatch('FedLogOut').then(() => {
                    //     location.reload()
                    // })
                });
                console.log(this,'确认')
               
                return Promise.reject('error');
            }else{
                return Promise.reject(res.data.message);
            }
            
        } else if (res.data.code === 502) {
            MessageBox.alert(res.data.message, 'error', {
                confirmButtonText: 'submit',
                type: 'error'
            });
            return Promise.reject('error');
        } else if (res.data.code === 301) {
            // MessageBox.alert('Please login first', 'error', {
            //     confirmButtonText: 'submit',
            //     type: 'error',
            //     callback: action => {
            //       isLogin = false
            //       store.commit("GO_LOGIN", isLogin);
            //       console.log(action,store,11111111111111111)
            //     }
            // })
            isLogin = false;
            store.commit("GO_LOGIN", isLogin);
            return Promise.reject('error');
        } else {
            
            return res.data;
        }
    },
    function (err) {
        return Promise.reject(err);
    }
)
 
// 封装get和post请求
export function get(url, params) {
    return instance.get(url, {params});
}
 
export function post(url, data) {
    return instance.post(url, data);
}
 
export default instance;
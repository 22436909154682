<template>
  <div class="home" ref="home">
    <div ref="scale" style="transform-origin:left top;">
      <headerView ref="headerView"></headerView>
      <div class="home-content">
        <div class="title-name">New Release</div>
        <div class="swiper-box">
          <el-carousel
            :interval="3000"
            type="card"
            height="400px"
            style="overflow-x: unset"
            indicator-position="none"
            arrow="never"
          >
            <Swiper v-for="(item,index) in swiperKist" :key="index">
              <div class="imgBox">
                <img class="novel-img" @click="jumpIntroduce(item.id)" :src="item.Logo" alt="">
                <div class="imgTit">Please Lock Me In, Male Lead</div>
              </div>
            </Swiper>
          </el-carousel>
        </div>
        <div class="title-name-tab">
          <div @click="weeklyList = 1;weekData(1)" :class="{'week-active' : weeklyList == 1}">week click</div>
          <div @click="weeklyList = 2;weekData(2)" :class="{'week-active' : weeklyList == 2}">week like</div>
          <div @click="weeklyList = 3;weekData(3)" :class="{'week-active' : weeklyList == 3}">week collect</div>
        </div>
        <bookList bookId="hot" :novelLsit="weekList" @mark="mark" name="weekData"></bookList>
        <div class="title-name">Recommend</div>
        <bookList bookId="recommend" :novelLsit="recommendList" @mark="mark" name="getRecommendList"></bookList>
      </div>
      <footerView></footerView>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import headerView from '@/components/headerView.vue'
import bookList from '@/components/bookList.vue'
import { getRecommendList, weekData, getSwiper } from "@/api/index" 
import Swiper from "../components/swiperView.vue"
import footerView from "../components/footerView.vue"
export default {
  name: 'HomeView',
  components: {
    headerView,Swiper,bookList,footerView
  },
  data () { 
    return {
      isOk:'',
      weeklyList: 1,
      swiperKist: [], //轮播图数据
      weekList: [], //周数据
      recommendList: [], //recommend数据,
    }
  },
  mounted () { 
    this.getSwiper()
    this.weekData(1)
    this.getRecommendList()
    console.log(this.$refs.scale.style, '屏幕宽度1111')
    if(window.screen.availWidth < 1020){
      let screenWidth = window.screen.availWidth
      let scaling = screenWidth / 1020
      // let left = (1 - scaling) / 2 * 1020
      // translateX(-${left}px)
      this.$refs.scale.style.transform = `scale(${scaling})`
      this.$refs.home.style.height = `${document.body.clientHeight * scaling + 20}px`
      this.$refs.home.style.width = `${screenWidth * 2.6}px`
      console.log(document.body.clientHeight, '屏幕宽度')
    }
  },
  methods: {
    // 收藏小说
    mark (name) { 
      this[name](1)
    },
    // 获取轮播图
    getSwiper () { 
      let obj = {
        pageno:1,
        pagesize:5
      }
      getSwiper(obj).then(res => {
        this.swiperKist = res.data.Main
      })
    },
    // 周数据
    weekData (type) { 
      let obj = {
        type:type,
        pageno:1,
        pagesize:20
      }
      weekData(obj).then(res => {
        this.weekList  = res.data.Main
      })
    },
    getRecommendList () { 
      let obj = {
        pageno:1,
        pagesize:20
      }
      getRecommendList(obj).then(res => {
        this.recommendList = res.data.Main
        if(this.recommendList && this.recommendList.length > 0){
          this.recommendList.forEach(item =>{
            item.Score = Number(item.Score)
          })
        }
      })
    },
    // 跳转到小说介绍页面
    jumpIntroduce (id) { 
      this.$router.push({
        path:'./introduce',
        query: {id:id}
      })
    },
  },
}
</script>
<style scoped>
.home{
  min-height: 100vh;
  overflow: hidden;
}
.home-content{
  width: 980px;
padding: 0px 20px;;
  margin: 0px auto;
  /* min-height: 1400px; */
}
::v-deep.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

::v-deep.el-carousel__item:nth-child(2n) {
  background-color: #f5f6fc;
}

::v-deep.el-carousel__item:nth-child(2n+1) {
  background-color: #f5f6fc;
}
::v-deep.el-carousel__item--card{
  width: 30% !important;
}
.novel-img{
  width: 100%;
  height: 400px;
}
.title-name{
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 60px 0px 20px 0px;
  z-index: 1;
  
  
}
.title-name-tab{
  font-size: 28px;
  color: #666;
  display: flex;
  margin: 60px 0px 40px 0px;
  cursor: pointer;
  transition: all 0.35s;
}
.title-name-tab>div{
  margin-right: 20px;
}
.week-active{
  color: #333;
  font-size: 32px;
  font-weight: 700;
  position: relative;
}
.week-active::after{
  position: absolute;
  content: '';
  left: 50%;
  top: 45px;
  width: 50px;
  height: 2px;
  background-color: #EB3349;
  transform: translateX(-50%);
}
.imgTit{
  font: 12px;
  color: #333;
  font-weight: 700;
  padding: 20px 0px;
}
/*

@media screen and (max-width : 390px) {

  .home-content{
    width: 100%;
    padding: 0px 20px;
  }
  .title-name-tab{
    font-size: 20px;
  }
  .title-name-tab>div{
    margin-right: 20px;
  }
  .week-active{
    font-size: 18px;
  }
  .week-active::after{
    position: absolute;
    content: '';
    left: 50%;
    top: 28px;
  }
  .title-name{
    font-size: 20px;
    margin: 20px 0px 10px 0px;
  }
  .swiper-box{
    width: 980px;
padding: 0px 20px;;
    transform: calc(0.5);
    height: 200px;
  }
  >>>.el-carousel{
    transform: scale(0.5) translateX(-595px)  translateY(-171px);
  }
}
*/

</style>

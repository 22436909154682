<template>
<div class="list-box" :id="bookId">
  <div v-for="(item,index) in novelLsit" :key="index" class="list-item">
    <div class="list-item-top">
    <div class="list-item-img-box" @click="jumpIntroduce(item.id)">
        <img class="list-item-img" :src="item.Logo" alt="">
    </div>
    <div>
        <div class="item-content">Title:<span>《{{item.Title}}》</span></div>
        <div class="item-content">Author:<span>{{ item.Author }}</span></div>
        <div class="item-content">Overview:<span>{{ item.Intro }}</span></div>
        <div class="item-content">Score:</div>
        <div class="item-content rate-box"> <el-rate
          v-model="item.Score"
          disabled
          show-score
          disabled-void-color="rgb(185 185 185);"
          text-color="#ff9900"
          score-template="{value}">
          </el-rate></div>
    </div>
    </div>
    <div class="list-item-bottom">
    <div class="bottom-item">
        <i :class="item.IsMark == '1' ? 'el-icon-star-on' : 'el-icon-star-off'" style="cursor: pointer;" @click="mark(item)"></i>
        <span>Mark</span>
        <span class="num">({{item.Mark}}+)</span>
    </div>
    <div class="bottom-item">
        <i class="el-icon-chat-round"></i>
        <span>comment</span>
        <span class="num">({{item.Comment}}+)</span>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import { setMark } from "@/api/index" 
export default {
  props: {
    bookId: {
      type:String,
      default: '',
    },
    novelLsit:{
      type:Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ()=>[],
    },
    name: {
      type:String,
      default: '',
    }
  },
  data(){
    return {
      isCollect:false,
      rateVaue:4,
    }
  },
  mounted() {
    this.scrollInit(this.bookId);
  },
  methods: {
    // 
    mark (item) { 
      this.isCollect = !this.isCollect
      let obj = {
        id:item.id
      }
      setMark(obj).then(() => {
        if(item.IsMark == '1'){
          this.$message({
            message: 'Collection successful',
            type: 'success'
          });
        }else{
          this.$message({
            message: 'Cancel Favorite',
            type: 'success'
          });
        }
        this.$emit('mark',this.name)
      })
    },
    
    // 跳转到小说介绍页面
    jumpIntroduce (id) { 
      this.$router.push({
        path: './introduce',
        query: {id:id}
      })
    },
    // 初始化与绑定监听事件方法
    scrollInit(id) {
      // 获取要绑定事件的元素
      const nav = document.getElementById(id)
       var flag; // 鼠标按下
       var downX; // 鼠标点击的x下标
       var scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
      if (flag) { // 判断是否是鼠标按下滚动元素区域
        var moveX = event.clientX; // 获取移动的x轴
        var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
        this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
      }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
  }
}
</script>

<style scoped>
.list-box{
  display: flex;
  width: 100%;
  overflow:scroll;
  overflow-y: hidden;
  height: 230px;
}
.list-box::-webkit-scrollbar {
  background-color:rgba(163, 163, 163, 0.1);border-radius:10px;
  height: 8px;
} 
/* .list-box::-webkit-scrollbar-button {
  display: none;
} */
/* .list-box::webkit-scrollbar {
  height: 2px;
}
::-webkit-scrollbar{width:6px;height:6px;

  background-color: #dadbdb;
  background-color: #f5f6fc;

}*/
.list-box::webkit-scrollbar {
  display: none;
  background-color:rgba(0,0,0,0.1);border-radius:10px;
}
::-webkit-scrollbar-thumb {background-color:rgba(0,0,0,0.1);border-radius:10px !important;}

::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,0.1);border-radius:10px;}

::-webkit-scrollbar-track{background-color:transparent; }
.list-item{
  
}

.list-item-top{
  display: flex;
  width: 328px;
}
.list-item-img-box{
   width: 110px;
  height: 140px;
  overflow: hidden;
  margin-right: 10px;
}
.list-item-img{
  width: 110px;
  height: 140px;
  transition: all 0.35s;
  cursor: pointer;
  
}
.list-item-img:hover{
  transform: scale(1.1);
}
.item-content{
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 162px;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}
.rate-box{
  transform: translateY(-10px);
  margin-bottom: 0px;
}
.item-content>span{
  font-size: 14px;
  color: #666;
  font-weight: 100;
  padding-left: 4px;
}
.bottom-item{
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
}
.bottom-item>.num{
  padding-left: 4px;
  color: #666;
}
.bottom-item>i{
  font-size: 20px;
  font-weight:400;
  color: #409EFF;
  color: #aab3bd;
  margin-right: 6px;
  transform: translateY(3px);
}
.el-icon-star-on{
  color: #Eb3349 !important;
}
</style>
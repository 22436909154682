import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },{
    path: '/introduce',
    name: 'introduce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/introduceView.vue')
  },{
    path: '/coins',
    name: 'coins',
    component: () => import('../views/coinsView.vue')
  },{
    path: '/chart',
    name: 'chart',
    component: () => import('../views/addCart.vue')
  },{
    path: '/series',
    name: 'series',
    component: () => import('../views/novelListView.vue')
  },{
    path: '/bookMark',
    name: 'bookMark',
    component: () => import('../views/bookMark.vue')
  },{
    path: '/noveContent',
    name: 'noveContent',
    component: () => import('../views/noveContent.vue')
  },{
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/paySuccess.vue')
  },{
    path: '/payError',
    name: 'payError',
    component: () => import('../views/payError.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

<template>
  <div style="width: 300px; margin: 0px auto;" v-loading="btnLogin">
    <el-form label-position="top" ref="loginForm" :rules="rules" label-width="80px" :model="loginForm">
      <el-form-item label="Username or Email Address *" prop="username">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="Password *" prop="passwd">
        <el-input v-model="loginForm.passwd" show-password></el-input>
      </el-form-item>
      <!-- <el-form-item label="Prove your humanity">
        3 + 1 = 
        <el-input-number v-model="loginForm.num" controls-position="right" :min="1" :max="10" style="width: 150px;"></el-input-number>
      </el-form-item> -->
    </el-form>
    <div class="login-btn-box">
      <div class="login-submit" @click="loginSubmit">Login</div>
      <div class="back-btn" @click="goBack">Back</div>
    </div>
    <div>
      <el-checkbox v-model="remberMe">Remember Me</el-checkbox>
      <span class="forget" @click="loosePass">Lost your password?</span>
    </div>
    <div class="tips" @click="goRegister" style="color: blue; text-decoration: underline; cursor: pointer;">
      No account, please click here to register.
    </div>
  </div>
</template>
<script>
import { login } from "@/api/index" 
import store from '../store/index'
export default {
  data () { 
    return {
      loginForm: {
        name:'',
        passwd:'',
      },
      remberMe:false,
      rules: {
        username: [
          { required: true, message: 'please enter Username or Email Address', trigger: 'change' }
        ],
        passwd: [
          { required: true, message: 'please enter password', trigger: 'change' }
        ],
      },
      btnLogin:false,
    }
  },
  methods: {
    goBack () { 
      this.$emit('goBack')
    },
    goRegister () { 
      this.$emit('goRegister')
    },
    loginSubmit () { 
      this.btnLogin = true
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let obj = {
            username:this.loginForm.username,
            passwd:this.loginForm.passwd,
          }
          login(obj).then(res => {
            localStorage.setItem('token',res.data)
            localStorage.setItem('username',this.loginForm.username)
            localStorage.setItem('passwd',this.loginForm.passwd)
            this.btnLogin = false
            this.$message({
              message: 'Login succeeded',
              type: 'success'
            });
            let isLogin = true
            store.commit("GO_LOGIN", isLogin);
            this.$emit('loginSubmit')
          }).catch(()=>{
            this.btnLogin = false
          })
        } else {
          return false;
        }
      });
    },
    loosePass () { 
      this.$emit('loosePass')
    },
  }
}
</script>
<style scoped>
.login-btn-box{
  display: flex;
  margin-bottom: 18px;
}
.login-submit{
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #fff;
  background-color: #eb3349;
  cursor: pointer;
 
}
.back-btn{
  margin-left: 10px;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #eb3349;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eb3349;;
}
.forget{
  padding-left: 20px;
  cursor: pointer;
}
.forget:hover{
  color: #eb3349;;
}
.tips{
  padding-top: 8px;
  font-size: 12px;
  color: #eb3349;
  cursor: pointer;
}
</style>
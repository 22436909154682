<template>
  <div style="width: 300px; margin: 0px auto;" v-loading="btnLogin">
    <div class="alert-title">Register For This Site.</div>
    <el-form label-position="top"  ref="registerForm" :rules="rules" label-width="80px" :model="registerForm">
      <el-form-item label="Username *" prop="username">
        <el-input v-model="registerForm.username"></el-input>
      </el-form-item>
      <el-form-item label="Email Address *" prop="email">
        <el-input v-model="registerForm.email"></el-input>
      </el-form-item>
      <el-form-item label="Password *" prop="passwd">
        <el-input v-model="registerForm.passwd" show-password></el-input>
      </el-form-item>
    </el-form>
    <div class="login-btn-box">
      <div class="login-submit" @click="registerSubmit">Register</div>
      <div class="back-btn" @click="goBack">Back</div>
    </div>
    <div>
      <span class="forget" @click="goLogin">Login</span>
      <span class="forget" @click="loosePass">Lost your password?</span>
    </div>
    <div class="tips">
      Tips： If you have not received the registration email, please check if the email has been intercepted or identified as spam.
    </div>
  </div>
</template>
<script>
import { register } from "@/api/index"
export default {
  data () { 
    return {
      registerForm: {
        name:'',
        email:'',
        passwd:'',
      },
      remberMe:false,
      rules: {
        username: [
          { required: true, message: 'please enter Username or Email Address', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'please enter email', trigger: 'change' }
        ],
        passwd: [
          { required: true, message: 'please enter password', trigger: 'change' }
        ],
      },
      btnLogin:false,
    }
  },
  methods: {
    goBack () { 
      this.$emit('goBack')
    },
    registerSubmit () { 
      this.btnLogin = true
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          let obj = {
            username:this.registerForm.username,
            email:this.registerForm.email,
            passwd:this.registerForm.passwd,
          }
          register(obj).then(() => {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: 'The registration link has been sent,<br>check your email and click the verification link',
              type: 'success'
            });
            this.btnLogin = false
            this.$emit('registerSubmit')
          }).catch(()=>{
            this.btnLogin = false
          })
        } else {
          return false;
        }
      });
    },
    goLogin () { 
      this.$emit('goLogin')
    },
    loosePass () { 
      this.$emit('loosePass')
    },
  }
}
</script>
<style scoped>
.alert-title{
  color: #ff0000;
}
.login-btn-box{
  display: flex;
  margin-bottom: 18px;
}
.login-submit{
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #fff;
  background-color: #eb3349;
  cursor: pointer;
 
}
.back-btn{
  margin-left: 10px;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  color: #eb3349;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eb3349;;
}
.forget{
  padding-right: 20px;
  cursor: pointer;
}
.forget:hover{
  color: #eb3349;;
}
.tips{
  padding-top: 8px;
  font-size: 12px;
  color: #eb3349;
}
</style>
<template>
  <div
    v-show="ready"
    class="el-carousel__item"
    :class="{
      'is-active': active,
      'el-carousel__item--card': $parent.type === 'card',
      'is-in-stage': inStage,
      specialIndex: specialIndex,
      'is-hover': hover,
      'is-animating': animating,
    }"
    @click="handleItemClick"
    :style="itemStyle"
  >
    <div
      v-if="$parent.type === 'card'"
      v-show="!active"
      class="el-carousel__mask"
    ></div>
    <slot></slot>
  </div>
</template>
<script>
import { autoprefixer } from 'element-ui/src/utils/util'
export default {
  name: 'ElCarouselItem',
  props: {
    name: String,
    label: {
      type: [String, Number],
      default: ''
    }
  },
 
  data () {
    return {
      hover: false,
      translate: 0,
      scale: 1,
      active: false,
      ready: false,
      inStage: false,
      specialIndex: false,
      animating: false
    }
  },
  created () {
    this.$parent && this.$parent.updateItems()
  },
 
  destroyed () {
    this.$parent && this.$parent.updateItems()
  },
 
  methods: {
    processIndex (index, activeIndex) {
      if (activeIndex == 0) {
        return index == 1 ? 1 : index == 2 ? 2 : index == 3 ? -2 : index == 4 ? -1 : 0
      }
      if (activeIndex == 1) {
        return index == 2 ? 1 : index == 3 ? 2 : index == 4 ? -2 : index == 0 ? -1 : 0
      }
      if (activeIndex == 2) {
        return index == 3 ? 1 : index == 4 ? 2 : index == 0 ? -2 : index == 1 ? -1 : 0
      }
      if (activeIndex == 3) {
        return index == 4 ? 1 : index == 0 ? 2 : index == 1 ? -2 : index == 2 ? -1 : 0
      }
      if (activeIndex == 4) {
        return index == 0 ? 1 : index == 1 ? 2 : index == 2 ? -2 : index == 3 ? -1 : 0
      }
    },
 
    calcCardTranslate (index) {
      return index * 180 + 320
    },
 
    calcTranslate (index, activeIndex, isVertical) {
      const distance = this.$parent.$el[isVertical ? 'offsetHeight' : 'offsetWidth']
      return distance * (index - activeIndex)
    },
 
    translateItem (index, activeIndex, oldIndex) {
      const parentType = this.$parent.type
      const parentDirection = this.parentDirection
      const length = this.$parent.items.length
      if (parentType !== 'card' && oldIndex !== undefined) {
        this.animating = index === activeIndex || index === oldIndex
      }
      index = this.processIndex(index, activeIndex, length)
      if (parentType === 'card') {
        if (parentDirection === 'vertical') {
          console.warn('[Element Warn][Carousel]vertical direction is not supported in card mode')
        }
        this.inStage = Math.round(Math.abs(index)) <= 1
        this.specialIndex = Math.round(Math.abs(index)) >= 3
        this.active = index === 0
        this.translate = this.calcCardTranslate(index, activeIndex)
        this.scale = Math.abs(index) == 0 ? 1 : Math.abs(index) == 1 ? 0.9 : Math.abs(index) == 2 ? 0.76 : 0.62
      } else {
        this.active = index === activeIndex
        const isVertical = parentDirection === 'vertical'
        this.translate = this.calcTranslate(index, activeIndex, isVertical)
      }
      this.ready = true
    },
 
    handleItemClick () {
      const parent = this.$parent
      if (parent && parent.type === 'card') {
        const index = parent.items.indexOf(this)
        parent.setActiveItem(index)
      }
    }
  },
 
  computed: {
    parentDirection () {
      return this.$parent.direction
    },
 
    itemStyle () {
      const translateType = this.parentDirection === 'vertical' ? 'translateY' : 'translateX'
      const value = `${translateType}(${this.translate}px) scale(${this.scale})`
      const style = {
        transform: value
      }
      return autoprefixer(style)
    }
  }
}
</script>
<style scoped>
.el-carousel__arrow--left {
  left: -14px !important;
}
.el-carousel__arrow--right {
  right: -18px !important;
}
.el-carousel__item {
  cursor: pointer;
  z-index: 1;
}
.el-carousel__item--card.is-in-stage {
  z-index: 2;
}
.el-carousel__item--card.is-active {
  z-index: 3;
}
.specialIndex {
  z-index: 0;
}
</style>